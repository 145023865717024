<template>
  <div
    :class="[
      boxStyle == 'two'
        ? 'product-box-two'
        : boxStyle == 'three'
        ? 'product-box-three'
        : boxStyle == 'four'
        ? 'product-box-four'
        : 'product-box-one'
    ]"
  >
    <div v-if="isLoading">
      <v-skeleton-loader
        type="image"
        :height="
          boxStyle == 'two'
            ? '70'
            : boxStyle == 'three'
            ? '150'
            : boxStyle == 'four'
            ? '130'
            : '310'
        "
      />
    </div>
    <div :class="['overflow-hidden', { 'rounded border': !noBorder }]" v-else>
      <div
        v-if="boxStyle == 'three'"
        class="position-relative bg-white"
        style="height: 147px; border-radius: 5px; overflow: hidden"
      >
        <img
          src="@/assets/img/product-three-bg.png"
          alt=""
          style="width: 387px; height: 147px"
        />
        <v-row align="center" no-gutters class="lex-nowrap absolute-full">
          <v-col :cols="null" class="minw-0 flex-shrink-0">
            <div :class="['px-3 d-flex flex-column']">
              <div class="fs-22 fc-vi mb-2">
                <template
                  v-if="
                    productDetails.base_price >
                    productDetails.base_discounted_price
                  "
                >
                  <del class="opacity-40">
                    {{ format_price(productDetails.base_price) }}
                  </del>
                  <span class="fw-700">
                    {{ format_price(productDetails.base_discounted_price) }}
                  </span>
                </template>
                <template v-else>
                  <span class="fw-700">
                    {{ format_price(productDetails.base_discounted_price) }}
                  </span>
                </template>
              </div>
              <h5
                :class="[
                  'opacity-60 fw-400 mb-4 lh-1-6 fs-13 text-truncate-2 h-40px'
                ]"
              >
                <router-link
                  :to="{
                    name: 'ProductDetails',
                    params: { slug: productDetails.slug }
                  }"
                  class="text-reset"
                >
                  {{ productDetails.name }}
                </router-link>
              </h5>
              <div class="d-flex align-center">
                <div class="me-3">
                  <template v-if="productDetails.stock">
                    <button
                      class="text-reset py-1 lh-1 align-center d-flex"
                      @click="
                        showAddToCartDialog({
                          status: true,
                          slug: productDetails.slug
                        })
                      "
                    >
                      <i
                        class="la la-shopping-cart fs-24 ts-05 me-1 primary--text"
                      ></i>
                      <span class="fs-17 primary--text">{{ $t('buy_now') }}</span>
                    </button>
                  </template>
                  <template v-else>
                    <span class="fw-700 fs-13 opacity-60">
                      {{ $t('out_of_stock') }}
                    </span>
                  </template>
                </div>
                <div class="mt-2 w-30px h-30px flex-center">
                  <template v-if="isThisWishlisted(productDetails.id)">
                    <button
                      class="text-red"
                      @click="removeFromWishlist(productDetails.id)"
                      type="button"
                    >
                      <i class="la la-heart ts-02 fs-24"></i>
                    </button>
                  </template>
                  <template v-else>
                    <button
                      class="text-red btn-heart"
                      @click="addNewWishlist(productDetails.id)"
                      type="button"
                    >
                      <i class="la la-heart heart-bg-white ts-02 fs-16"></i>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="auto" class="flex-shrink-0">
            <div class="position-relative">
              <div v-if="discount > 0" class="discount-badge">
                {{ $t('off') }} {{ discount }}%
              </div>
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug }
                }"
                class="text-reset d-block lh-0 text-center"
              >
                <img
                  :src="addResourceImage(productDetails.thumbnail_image)"
                  :alt="productDetails.name"
                  @error="imageFallback($event)"
                  class="img-fit size-150px"
                />
              </router-link>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-row
        v-else-if="boxStyle == 'vi-one'"
        align="center"
        no-gutters
        class="bg-white"
      >
        <v-col cols="12" class="flex-shrink-0">
          <div class="position-relative">
            <div
              v-if="discount > 0"
              class="discount-badge"
            >
              {{ $t('off') }} {{ discount }}%
            </div>
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { slug: productDetails.slug }
              }"
              class="text-reset d-block lh-0 text-center"
            >
              <img
                :src="addResourceImage(productDetails.thumbnail_image)"
                :alt="productDetails.name"
                @error="imageFallback($event)"
                class="img-fit h-180px"
              />
            </router-link>

            <div class="heart-top">
              <template v-if="isThisWishlisted(productDetails.id)">
                <button
                  class="text-red pt-1"
                  @click="removeFromWishlist(productDetails.id)"
                  type="button"
                >
                  <i class="la la-heart ts-02 fs-24"></i>
                </button>
              </template>
              <template v-else>
                <button
                  class="text-red btn-heart mt-1"
                  @click="addNewWishlist(productDetails.id)"
                  type="button"
                >
                  <i class="la la-heart text-white ts-02 fs-16"></i>
                </button>
              </template>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="minw-0 flex-shrink-0"
        >
          <div
            class="px-3 d-flex flex-column py-2"
          >
            <div
              class="fs-22 text-center fc-vi-red"
            >
              <template
                v-if="
                  productDetails.base_price >
                  productDetails.base_discounted_price
                "
              >
                <del class="opacity-40">
                  {{ format_price(productDetails.base_price) }}
                </del>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
              <template v-else>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
            </div>
            <h5
              class="fw-400 mb-2 lh-1-6 fs-13 text-truncate-2 h-40px"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug }
                }"
                class="text-reset"
              >
                {{ productDetails.name }}
              </router-link>
            </h5>
            <div class="d-flex align-center" >
              <div class="flex-grow-1 me-1">
                <template v-if="productDetails.stock">
                  <button
                    class="text-reset py-1 lh-1 align-center d-flex vi-btn-box"
                    :style="`${isBtnBg ? 'background-color: #85EEDF' : '' }`"
                    @click="
                      showAddToCartDialog({
                        status: true,
                        slug: productDetails.slug
                      })
                    "
                  >
                    <!-- <i class="la la-shopping-cart fs-20 ts-05 me-1"></i> -->
                    <span class="fw-700 fs-13" :class="isBtnBg ? 'text-white' : 'text-white'">{{ $t('buy_now') }}</span>
                  </button>
                </template>
                <template v-else>
                  <span class="fw-700 fs-13 opacity-60">
                    {{ $t('out_of_stock') }}
                  </span>
                </template>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-else-if="boxStyle == 'four'"
        align="center"
        no-gutters
        class="flex-nowrap bg-white"
      >
        <v-col cols="auto" class="flex-shrink-0">
          <div class="position-relative">
            <div
              v-if="discount > 0"
              class="discount-badge"
            >
              {{ $t('off') }} {{ discount }}%
            </div>
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { slug: productDetails.slug }
              }"
              class="text-reset d-block lh-0 text-center"
            >
              <img
                :src="addResourceImage(productDetails.thumbnail_image)"
                :alt="productDetails.name"
                @error="imageFallback($event)"
                class="img-fit size-130px"
              />
            </router-link>
          </div>
        </v-col>
        <v-col
          :cols="null"
          class="minw-0 flex-shrink-0"
        >
          <div
            class="px-3 d-flex flex-column py-2"
          >
            <div
              class="fs-22 mb-2 fc-vi-red"
            >
              <template
                v-if="
                  productDetails.base_price >
                  productDetails.base_discounted_price
                "
              >
                <del class="opacity-40">
                  {{ format_price(productDetails.base_price) }}
                </del>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
              <template v-else>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
            </div>
            <h5
              class="fw-400 mb-2 lh-1-6 fs-13 text-truncate-2 h-40px"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug }
                }"
                class="text-reset"
              >
                {{ productDetails.name }}
              </router-link>
            </h5>
            <div class="d-flex align-center justify-space-between">
              <div class="me-3">
                <template v-if="productDetails.stock">
                  <button
                    class="text-reset py-1 lh-1 align-center d-flex vi-btn-box"
                    @click="
                      showAddToCartDialog({
                        status: true,
                        slug: productDetails.slug
                      })
                    "
                  >
                    <!-- <i class="la la-shopping-cart fs-20 ts-05 me-1"></i> -->
                    <span class="fw-700 fs-13 text-white">{{ $t('buy_now') }}</span>
                  </button>
                </template>
                <template v-else>
                  <span class="fw-700 fs-13 opacity-60">
                    {{ $t('out_of_stock') }}
                  </span>
                </template>
              </div>
              <div>
                <template v-if="isThisWishlisted(productDetails.id)">
                  <button
                    class="text-red pa-1 lh-1"
                    @click="removeFromWishlist(productDetails.id)"
                    type="button"
                  >
                    <i class="la la-heart ts-02 fs-18"></i>
                  </button>
                </template>
                <template v-else>
                  <button
                    class="text-red pa-1 lh-1"
                    @click="addNewWishlist(productDetails.id)"
                    type="button"
                  >
                    <i class="la la-heart-o ts-02 fs-18"></i>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-else
        align="center"
        no-gutters
        :class="[{ 'flex-nowrap': boxStyle != 'one' }]"
      >
        <v-col :cols="boxStyle == 'one' ? '12' : 'auto'" class="flex-shrink-0">
          <div class="position-relative">
            <div
              v-if="discount > 0 && boxStyle != 'two'"
              class="discount-badge"
            >
              {{ $t('off') }} {{ discount }}%
            </div>
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { slug: productDetails.slug }
              }"
              class="text-reset d-block lh-0 text-center"
            >
              <img
                :src="addResourceImage(productDetails.thumbnail_image)"
                :alt="productDetails.name"
                @error="imageFallback($event)"
                :class="[
                  'img-fit',
                  boxStyle == 'two'
                    ? 'size-70px'
                    : boxStyle == 'four'
                    ? 'size-130px'
                    : 'h-180px'
                ]"
              />
            </router-link>
          </div>
        </v-col>
        <v-col
          :cols="boxStyle == 'one' ? '12' : null"
          class="minw-0 flex-shrink-0"
        >
          <div
            :class="[
              'px-3 d-flex flex-column',
              boxStyle == 'two' ? 'py-1' : 'py-2'
            ]"
          >
            <div
              :class="[boxStyle == 'two' ? 'order-2 fs-14 lh-1 fc-zi' : 'fs-16 mb-2']"
            >
              <template
                v-if="
                  productDetails.base_price >
                  productDetails.base_discounted_price
                "
              >
                <del class="opacity-40">
                  {{ format_price(productDetails.base_price) }}
                </del>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
              <template v-else>
                <span class="fw-700">
                  {{ format_price(productDetails.base_discounted_price) }}
                </span>
              </template>
            </div>
            <h5
              :class="[
                'opacity-60 fw-400 mb-2 lh-1-6',
                boxStyle == 'two'
                  ? 'text-truncate fs-12'
                  : 'fs-13 text-truncate-2 h-40px'
              ]"
            >
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { slug: productDetails.slug }
                }"
                class="text-reset"
              >
                {{ productDetails.name }}
              </router-link>
            </h5>
            <div class="d-flex align-center" v-if="boxStyle != 'two'">
              <div :class="[boxStyle == 'four' ? 'me-3' : 'flex-grow-1 me-1']">
                <template v-if="productDetails.stock">
                  <button
                    class="text-reset py-1 lh-1 align-center d-flex"
                    @click="
                      showAddToCartDialog({
                        status: true,
                        slug: productDetails.slug
                      })
                    "
                  >
                    <i class="la la-shopping-cart fs-20 ts-05 me-1"></i>
                    <span class="fw-700 fs-13">{{ $t('buy_now') }}</span>
                  </button>
                </template>
                <template v-else>
                  <span class="fw-700 fs-13 opacity-60">
                    {{ $t('out_of_stock') }}
                  </span>
                </template>
              </div>
              <div>
                <template v-if="isThisWishlisted(productDetails.id)">
                  <button
                    class="text-red pa-1 lh-1"
                    @click="removeFromWishlist(productDetails.id)"
                    type="button"
                  >
                    <i class="la la-heart ts-02 fs-18"></i>
                  </button>
                </template>
                <template v-else>
                  <button
                    class="text-red pa-1 lh-1"
                    @click="addNewWishlist(productDetails.id)"
                    type="button"
                  >
                    <i class="la la-heart-o ts-02 fs-18"></i>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    isLoading: { type: Boolean, required: true, default: true },
    boxStyle: { type: String, default: 'one' },
    noBorder: { type: Boolean, default: false },
    isBtnBg: { type: Boolean, default: false },
    productDetails: { type: Object, required: true, default: () => ({}) }
  },
  data: () => ({}),
  computed: {
    ...mapGetters('wishlist', ['isThisWishlisted']),
    ...mapGetters('cart', ['isThisInCart', 'findCartItemByVariationId']),
    discount() {
      return this.discount_percent(
        this.$props.productDetails.base_price,
        this.$props.productDetails.base_discounted_price
      )
    }
  },
  methods: {
    ...mapActions('wishlist', ['addNewWishlist', 'removeFromWishlist']),
    ...mapActions('cart', ['addToCart', 'updateQuantity']),
    ...mapMutations('auth', ['showAddToCartDialog']),
    addCart() {
      if (!this.$props.productDetails.is_variant) {
        this.addToCart({
          variation_id: this.$props.productDetails.variations[0].id,
          qty: this.$props.productDetails.min_qty
        })
      }
    },
    updateCart(type, cart_id) {
      this.updateQuantity({
        type: type,
        cart_id: cart_id
      })
    }
  }
}
</script>

<style scoped>
.absolute-full {
  background: transparent !important;
}

.bg-white {
  background-color: #fff;
}

.fc-vi {
  color: #00CCAE;
}

.fc-vi-red {
  color: #FF5722;
}

.btn-heart {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF5722;
}

.text-white {
  color: #fff;
}

.vi-btn-box {
    height: 30px;
    max-width: 100%;
    min-width: 148px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #85EEDF;
  }

@media (max-width: 750px) {
  .vi-btn-box {
    height: 24px;
    max-width: 160px;
    min-width: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #85EEDF;
  }
}

.heart-top {
  position: absolute;
  top: 12px;
  right: 12px;
}

.fc-zi {
  color: #85EEDF;
}

.heart-bg-white {
  color: #fff;
}
</style>
